<div class="container" *ngIf="experience">
  <div class="header-container">
    <div *ngIf="experience.logoUrl" [ngClass]="'logo-' + experience.logoAlignment" [style.height.px]="experience.logoHeight">
      <img class="header-image" [attr.src]="getSecureLogoUrl() | secure | async" alt="" [style.minHeight.px]="experience.logoHeight" />
    </div>
  </div>
  <div *ngFor="let page of experience.pages; let pageIndex = index">
    <next-viewer-page
      *ngIf="pageIndex === (selectedPage | async)"
      [form]="form"
      [formId]="formId"
      [experience]="experience"
      [page]="page"
      [initialState]="initialState"
      [attachments]="attachments"
      [needsValidation]="needsValidation"
      (valueChanged)="emitValueChanged($event)">
    </next-viewer-page>
  </div>
</div>
