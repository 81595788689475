import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import {
  DateFormat,
  InputType,
  SubmissionDataType,
  TextboxFieldDTO,
  TextboxInputType,
} from '@next/shared/common';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FieldBaseComponent } from '../field-base/field-base.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { DateRangeValidatorFn } from '../../utilities';

@Component({
  selector: 'next-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.css'],
  providers: [CurrencyPipe, DatePipe]
})
export class TextboxComponent extends FieldBaseComponent implements OnInit, AfterViewInit {
  TextboxInputType: typeof TextboxInputType = TextboxInputType;
  componentList: string[] = [ TextboxInputType.SOCIAL, TextboxInputType.PHONE, TextboxInputType.CREDITCARDDIGITS ];

  type: { alphanumeric, numeric, alpha, currency, postalcode, shortdate2, shortdate4 } = {
    alphanumeric : 'text',
    numeric : 'tel',
    alpha : 'text',
    currency : 'text',
    postalcode: 'tel',
    shortdate2: 'tel',
    shortdate4: 'tel'
  };

  inputMode: { alphanumeric, numeric, alpha, email } = {
    alphanumeric : 'text',
    numeric : 'numeric',
    alpha : 'text',
    email : 'email'
  };

  pattern: { alpha, numeric, email, currency, postalcode, shortdate2, shortdate4 } = {
    // eslint-disable-next-line no-useless-escape
    alpha : '^([a-zA-Z.\\- \',\"]*)$',
    numeric : '^-?[0-9]\\d*(\\.\\d+)?$',
    email: /^[a-zA-Z\d\-_.+^@]+@[a-zA-Z\d\-_^.]+.[a-zA-Z\d\-_.^@]$/,
    // eslint-disable-next-line no-useless-escape
    currency : '^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{2})?$',
    postalcode: '^[0-9]{5}(-[0-9]{4})?$',
    shortdate2: '^[0-1]{1}[0-9]{1}/[0-9]{2}',
    shortdate4: '^[0-1]{1}[0-9]{1}/19{1}[0-9]{2}|[0-1]{1}[0-9]{1}/20{1}[0-9]{2}',
  };

  masking: { alpha, numeric } = {
    alpha : `U{${this.field?.maxChar||255}}`,
    numeric : 'V0*.0*',
  };

  maskPatterns: { numeric, alpha } = {
    numeric: {
      'V': { pattern: new RegExp(/-|\d/) },
      '0': { pattern: /\d/ }
    },
    alpha: {
      'U': { pattern: /^([a-zA-Z.\-',"]*)$/ }
    }
  };

  datePickerValue: any;
  datePickerConfig: Partial<BsDatepickerConfig>;
  dateMask = 'M0/d0/0000';
  dateTextInputFg: FormGroup = new FormGroup({
      value: new FormControl('', { updateOn: 'blur' })
    }
  );

  ShortDate2Mask = 'M0/00'
  ShortDate4Mask = 'M0/0000'

  newField = false;
  @Input() form: FormGroup;
  @Input() field: TextboxFieldDTO;
  @Input() initialState: any;
  @Input() needsValidation: boolean;

  constructor (
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe) {
    super();
  }

  ngOnInit(): void {
    this.setTempValueControlSwitching(this.field.name);
    const initData: any = this.initialState[this.field.name];

    if (this.field.inputType === TextboxInputType.DATE) {
      this.dateMask = this.field.dateFormat === DateFormat.MMDDYYYY ? 'M0/d0/0000' : 'd0/M0/0000';

      // Configure datepicker
      this.datePickerConfig = Object.assign({},{
        minDate: this.field.pastDates ? new Date(1900, 1, 1) : new Date(),
        maxDate: this.field.futureDates ? null : new Date(),
        showWeekNumbers: false,
        dateInputFormat: DateFormat.YYYYMMDD
      });
    }

    if (this.field.regularExpression) {
      (this.pattern as any).regularexpression = this.field.regularExpression;
    }
    if (!this.form.contains(this.field.name)) {
      const options: any = this.field.required ? { updateOn: 'blur', validators: [Validators.required] } : { updateOn: 'blur', validators: [] };

      if (this.field.inputType === InputType.Numeric) {
        if (this.field.minValue) {
          options.validators.push(Validators.min(this.field.minValue));
        }

        if (this.field.maxValue) {
          options.validators.push(Validators.max(this.field.maxValue));
        }
      }

      else if (this.field.inputType === InputType.Date || this.field.inputType === InputType.ShortDate2 || this.field.inputType === InputType.ShortDate4) {
        options.validators.push(DateRangeValidatorFn(this.field));
      }

      this.newField = true;

      this.valueFormGroup = new FormGroup({
        Text: new FormControl(initData.Value?.Text || '', options),
        Format: new FormControl(this.field.inputType)
      });

      /* Date input-type includes a control for preferred format */
      if (this.field.inputType === InputType.Date) {
        this.valueFormGroup.addControl('DateFormat', new FormControl(this.field.dateFormat || DateFormat.MMDDYYYY));
      }

      this.form.addControl(this.field.name, new FormGroup({
        Type: new FormControl(SubmissionDataType.Text),
        Value: this.valueFormGroup
      }));

    }
    else {
      this.valueFormGroup = this.form.get(`${this.field.name}.Value`) as FormGroup;

      /* Dates setup Text & Calendar value from form control */
      if (this.field.inputType === InputType.Date && this.valueFormGroup.value.Text) {
        const data = this.valueFormGroup.value.Text.split('-');
        const date = new Date(+data[0], +data[1] - 1, +data[2]);
        if (date && date instanceof Date && !isNaN(date.getDate())) {
          this.datePickerValue = date;
        }
        else {
          this.dateTextInputFg.patchValue({['value']: this.valueFormGroup.value.Text});
        }
      }

    }
  }

  ngAfterViewInit(): void {
    this.valueFormGroup.valueChanges.subscribe((n) => {
      if (n.Text) {
        /* Patch hundredth decimal to currency value on change */
        if (this.field.inputType === InputType.Currency) {
          const value = this.currencyPipe.transform(this.valueFormGroup.value['Text'], '', '', '.2-2').replace(/,/g, '');
          this.valueFormGroup.patchValue({ ['Text']: value }, { emitEvent: false });
        }
        this.valueChanged.emit(this.field);
      }
    });

    setTimeout(() => {
      if (this.field.inputType === InputType.PostalCode) {
        const postalCodeVal: HTMLInputElement = <HTMLInputElement>document.getElementById(this.field.name);
        if (postalCodeVal) {
          this.valueFormGroup.patchValue({
            ['Text']: postalCodeVal.value
          }, {
            emitEvent: false
          });
        }
      }

      const fControl: FormControl = <FormControl>this.valueFormGroup.get('Text');
      fControl.statusChanges.subscribe((status: any) => {
        this.updateStatus(status);
        this.newField = false;
      });
    });
  }

  textInputSetDate(e: any) {
    let date: Date;
    const data = e.target.value.split('/') || '';

    if (this.field.dateFormat === DateFormat.MMDDYYYY) {
      date = new Date(+data[2], +data[0] - 1, +data[1]);
    }
    if (this.field.dateFormat === DateFormat.DDMMYYYY) {
      date = new Date(+data[2], +data[1] - 1, +data[0]);
    }
    date.setHours(0,0,0,0);

    /* If valid date from text, set datePicker value, which will then
     * patch the value to the form control */
    if (date && date instanceof Date && !isNaN(date.getDate())) {
      this.datePickerValue = date;
    }
    else {
      /* If invalid date, patch string literal and expect invalid errors */
      this.valueFormGroup.patchValue( {['Text']: e.target.value });
    }
  }


  datePickerSetDate(date: Date) {
    if (date && date instanceof Date && !isNaN(date.getDate())) {
      date.setHours(0,0,0,0);

      const formValue = this.datePipe.transform(date, DateFormat.YYYYMMDD);
      const textValue = this.datePipe.transform(date, this.field.dateFormat);
      // Patch the value Date form control
      this.valueFormGroup.patchValue({['Text']: formValue}, { emitEvent: true });
      // Patch the text Date control
      this.dateTextInputFg.patchValue({['value']: textValue }, { emitEvent: false });
    }
  }

  public getFormattingError(fControl: FormControl | AbstractControl): any {
    const type = this.field.inputType;
    if (fControl.errors.required) {
      return 'REQUIRED_FIELD';
    }

    if (fControl.errors.maxlength && fControl.errors.maxlength.actualLength > fControl.errors.maxlength.requiredLength) {
      return 'TEXTBOX.INVALID_MAXLENGTH';
    }

    if (fControl.errors.min) {
      return 'TEXTBOX.INVALID_MIN';
    }

    if (fControl.errors.max) {
      return 'TEXTBOX.INVALID_MAX';
    }

    switch (type) {
      case InputType.Alpha:
        return 'TEXTBOX.INVALID_ALPHA';
      case InputType.Numeric:
        return 'TEXTBOX.INVALID_NUM';
      case InputType.Phone:
        return 'TEXTBOX.INVALID_PHONE';
      case InputType.Currency:
        return 'TEXTBOX.INVALID_CURRENCY';
      case InputType.Date:
        if (fControl.errors.DateRange) {
          return 'TEXTBOX.INVALID_DATERANGE';
        }
        return 'TEXTBOX.INVALID_DATE';
      case InputType.Email:
        return 'TEXTBOX.INVALID_EMAIL';
      case InputType.PostalCode:
        return 'TEXTBOX.INVALID_POSTALCODE';
      case InputType.CreditCardDigits:
        return 'TEXTBOX.INVALID_CCDIGITS';
      case InputType.Social:
        return 'TEXTBOX.INVALID_SSN';
      case InputType.RegularExpression:
        return "TEXTBOX.INVALID_FORMAT";
      case InputType.ShortDate2:
        return 'TEXTBOX.INVALID_SHORTDATE2';
      case InputType.ShortDate4:
        if (fControl.errors.DateRange) {
          return 'TEXTBOX.INVALID_DATERANGE';
        }
        return 'TEXTBOX.INVALID_SHORTDATE4';
    }
  }

  getFormattingErrorParam(fControl: FormControl | AbstractControl): any {
    if (fControl.errors.min) {
      return { value: fControl.errors.min.min}
    }

    if (fControl.errors.max) {
      return { value: fControl.errors.max.max}
    }

    return { };
  }
}
