<div class="form-group" [formGroup]="form">
  <!--POPUP CLOSED CONTENT-->
  <button
    *ngIf='!loading'
    id='{{ field.name + "_open" }}'
    (click)="openPopup()"
    [ngClass]="{'rounded border-danger' : valueFormGroup.invalid}"
    type='button'
    role='button'
    class="btn button-inverse btn-primary btn-block position-relative">
    <fa-icon class="fa pr-2" [icon]="['fas', 'camera']" aria-hidden="true" size="1x"></fa-icon>
    <span *ngIf="!b64e">{{ field.preButtonText }}</span>
    <span *ngIf='b64e'>{{field.postButtonText || ('PHOTO.POST_BUTTON_ALT'|translate) + field.title }}</span>
    <span *ngIf="b64e" class="position-absolute right-2 check-circle">
      <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
    </span>
    <span *ngIf="valueFormGroup.invalid" class="position-absolute right-2 exclamation-triangle">
      <fa-icon [icon]="['fas', 'exclamation-triangle']"></fa-icon>
    </span>
  </button>
  <button
    *ngIf='loading'
    id='{{ field.name + "_loading" }}'
    [ngClass]="{'rounded border-danger' : valueFormGroup.invalid}"
    [disabled]='true'
    type='button'
    role='button'
    class="btn button-inverse btn-primary btn-block position-relative">
    <fa-icon [icon]='["fas", "spinner"]' pulse='true' size='lg'></fa-icon>
  </button>

  <!--POPUP OPENED CONTENT-->
  <div *ngIf='display === DisplayStatus.OPENED' [@popupState]="display" class="popup p-2">
    <div class="container-fluid popup-scroll-area text-sizing">

      <div class="title">
        <h2>{{field.title}}</h2>
      </div>
      <hr>
      <div *ngIf='!b64e' class="header-text">
        {{field.preHeaderText}}
      </div>
      <div *ngIf='b64e' class="header-text">
        {{ field.postHeaderText || ('PHOTO.POST_HEADER_ALT'|translate) }}
      </div>

      <div class="take-photo-container">
        <button id='{{ field.name + "file-input-button" }}' (click)="file.click()" class="button button-inverse btn btn-primary btn-block pb-1">
          <fa-icon class="fa pr-2" [icon]="['fas', 'camera']" aria-hidden="true" size="1x"></fa-icon>
          {{ b64e ? ('PHOTO.RETAKE_PHOTO'|translate) : ('PHOTO.TAKE_PHOTO'|translate)}}
        </button>
        <input
          id='{{ field.name }}'
          #file
          type="file"
          (click)='onChange([])'
          (change)="onChange(file.files)"
          class='d-none'
          accept="image/bmp, image/jpeg, image/jpg, image/gif, image/png, image/tiff"/>
      </div>

      <div *ngIf='b64e' class="d-flex justify-content-between mt-1">
        <button
          id='{{ field.name + "_rotate" }}'
          class="btn btn-primary button w-25"
          (click)="onRotate()">
          <fa-icon class="fa pr-2" [icon]="['fas', 'sync-alt']" aria-hidden="true"></fa-icon>
          <span class='d-none d-md-inline-block'>{{'PHOTO.ROTATE' | translate}}</span>
        </button>

        <button
          id='{{ field.name + "_clear" }}'
          class="btn btn-primary button w-25"
          (click)="clearImage();this.valueFormGroup.get('FileID').markAsDirty()">
          <fa-icon class="fa pr-2" [icon]="['fas', 'ban']" aria-hidden="true"></fa-icon>
          <span class='d-none d-md-inline-block'>{{'PHOTO.CLEAR' | translate}}</span>
        </button>
      </div>

      <div *ngIf='b64e' class='d-inline-block w-100 mt-3 image-container'>
        <img
          #image
          id='{{ field.name + "_image_view" }}'
          class='image w-100 h-100'
          src='{{ this.b64e }}'
          alt='{{ field.name }}'>
      </div>

      <div *ngIf='!b64e' class='d-inline-block w-100 mt-3 image-container'>
        <div
          class='d-flex w-100 h-100 justify-content-center align-items-center dropzone-container'
          [ngClass]="{'dropzone-active' : dropzoneActive}"
          (click)='file.click()'
          (drop)='onDrop($event)'
          (dragover)='onDragOver($event)'
          (dragleave)='onDragLeave()'>
          <fa-icon class="justify-content-center align-items-center text-muted" [icon]="['fas', 'upload']" size="2x"></fa-icon>
        </div>
      </div>
    </div>

    <div class="popup-bottom">
      <button id='{{ field.name + "_submit" }}' type='submit' role='button' class="button btn btn-primary btn-block" (click)="onClosePopup()">
        {{ (b64e && valueFormGroup.dirty) ? ('PHOTO.UPLOAD' | translate) : ('PHOTO.DONE' | translate) }}
      </button>
    </div>
  </div>
</div>

<!--PROGRESS BAR OVERLAY-->
<div class="spinner-container" *ngIf='loading && display === DisplayStatus.OPENED' [@fadeInOut]>
  <img src='assets/images/interlace.gif' alt='Loading In Progress' class='spinner mb-2'>
  <div class='container-fluid justify-content-center mt-4'>
    <div class='row justify-content-center'>
      <div class='col-md-6 col-10'>
        <h4 class='w-100 text-center mt-1'>{{ loadingMessage }}</h4>
        <progressbar
          class='align-items-center ih-progress-bar'
          [animate]="false"
          [value]="loadingProgress"
          [striped]='true'
          [max]='100'
          type="success">
        </progressbar>
      </div>
    </div>
  </div>
</div>
